import ReactRailsUJS from "@janklimo/react_ujs";

import { transformImports } from "./utils";

/**
 * Support requiring React components using react_component helper relative to this directory.
 * NOTE: This approach currently doesn't allow us to render two components imported in two
 * different entrypoints on the same page.
 */
const imports = import.meta.glob(["~/views/**/*.tsx"], {
  eager: true,
});

const components = transformImports(imports, "/views/");

ReactRailsUJS.getConstructor = (name) => components[name].default;
