import { annotate, annotationGroup } from "rough-notation";
import "whatwg-fetch";

import Header from "../components/header";

import "../views";

const isHomepage = document.querySelector(".homepage");
const renderIcons = document.querySelector(".bouncy-icons");

if (renderIcons) {
  const header = new Header();
  header.render();
}

if (isHomepage) {
  const a1 = annotate(document.querySelector(".pen"), {
    type: "underline",
    multiline: true,
    color: "#77f7bb",
  });
  const a2 = annotate(document.querySelector(".highlighter"), {
    type: "highlight",
    multiline: true,
    color: "#77f7bb",
  });

  setTimeout(() => {
    const ag = annotationGroup([a1, a2]);
    ag.show();
  }, 1000);
}
