import { transform } from "lodash-es";

export const transformImports = (imports: Record<string, any>, folder: string) =>
  transform(
    imports,
    (result, value, key) => {
      const name = key.replace(".tsx", "").replace("/index", "").replace(folder, "");
      Object.assign(result, { [name]: value });
    },
    {}
  );
